import axios from "axios";
import { SERVER_PATH } from "../../config/env";
import { errorToaster, successToaster } from "./../../utils/index";

// google auth client id
// export const clienId =
//   "541441021566-lmeq5iub6vvrd78bgnjbmr8gershnu0p.apps.googleusercontent.com";

// Ktownaaricreatives
export const clienId =
  "443851043612-p76cg9sbb83i8ficr3139ko1pr2g25i2.apps.googleusercontent.com";

// google auth success
export const onSuccess = async (data) => {
  const code = await axios
    .post(`${SERVER_PATH}post/user.php`, {
      user_id: data.email,
      user_name: data.name,
      address: "0",
      mobile_no: "0",
      avatar: "0",
      status: "0",
      state: "0",
      no_purchases: "0",
      total_costof_purchases: "0",
    })
    .then((res) => {
      return res.status;
    });
  successToaster({ msg: "Login Successful" });
  return code;
};

// google auth failure
export const onFailure = (res) => {
  errorToaster({ msg: "Somthing went wrong" });
};

// google auth logout
export const onLogoutSuccess = () => {
  successToaster({ msg: "Logout Successful" });
};

// api address
export const apiAddress = "http://192.168.125.72/ecom/controllers/api";
