import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import qrImg from "../../assets/img/paymentqr.jpg";

export default function DialogBox({
  show,
  handleClose,
  handleNext,
  checkout,
  onChangeHandler,
}) {
  return (
    <>
      <Modal
        show={show === "open" ? true : show === "upload" ? true : false}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Payment Options</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {show === "open" ? (
            <>
              <img
                src={qrImg}
                alt="sengam janani12"
                width="60%"
                height="60%"
                className="mb-3"
              ></img>
              <div>
                <h6>
                  UPI ID:<span>snegamjanani12@okicici</span>
                </h6>
              </div>
            </>
          ) : show === "upload" ? (
            <Form.Group controlId="formFile" className="mb-3 text-left">
              <Form.Label>Upload Screenshot</Form.Label>
              <Form.Control type="file" onChange={(e) => onChangeHandler(e)} />
            </Form.Group>
          ) : (
            <h6>Somthing Went Wrong Go Back!</h6>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          {show === "open" ? (
            <Button variant="success" onClick={handleNext}>
              Next
            </Button>
          ) : show === "upload" ? (
            <Button variant="success" onClick={checkout}>
              Finish
            </Button>
          ) : null}
        </Modal.Footer>
      </Modal>
    </>
  );
}
