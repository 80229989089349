import React, { useState } from "react";
import { Container } from "react-bootstrap";
import Table from "react-bootstrap/Table";

export const Sizechart = () => {
  const [sizeView, setSizeView] = useState(true);

  return (
    <>
      <Container className="py-5">
        <div className="d-flex justify-content-between">
          <h2 className="mb-4">Sizechart</h2>
          <div
            class="btn-group d-inline-block pt-2"
            role="group"
            aria-label="Basic example">
            <button
              type="button"
              class="btn btn-sm btn-outline-primary"
              onClick={() => setSizeView(true)}>
              in
            </button>
            <button
              type="button"
              class="btn btn-sm btn-outline-primary"
              onClick={() => setSizeView(false)}>
              cm
            </button>
          </div>
        </div>
        {sizeView ? (
          <Table className="sizechartTable">
            <tr>
              <th>Brand Size</th>
              <td>34</td>
              <td>36</td>
              <td>38</td>
              <td>40</td>
              <td>42</td>
            </tr>
            <tr>
              <th>Bust</th>
              <td>34</td>
              <td>36</td>
              <td>38</td>
              <td>40</td>
              <td>42</td>
            </tr>
            <tr>
              <th>Waist</th>
              <td>27</td>
              <td>29</td>
              <td>31</td>
              <td>33</td>
              <td>35</td>
            </tr>
            <tr>
              <th>Shoulder</th>
              <td>13.5</td>
              <td>14</td>
              <td>14.5</td>
              <td>15</td>
              <td>15.5</td>
            </tr>
          </Table>
        ) : (
          <Table className="sizechartTable">
            <tr>
              <th>Brand Size</th>
              <td>34</td>
              <td>36</td>
              <td>38</td>
              <td>40</td>
              <td>42</td>
            </tr>
            <tr>
              <th>Bust</th>
              <td>86.4</td>
              <td>91.4</td>
              <td>96.5</td>
              <td>101.6</td>
              <td>106.7</td>
            </tr>
            <tr>
              <th>Waist</th>
              <td>68.6</td>
              <td>73.7</td>
              <td>78.7</td>
              <td>83.8</td>
              <td>88.9</td>
            </tr>
            <tr>
              <th>Shoulder</th>
              <td>34.3</td>
              <td>35.6</td>
              <td>36.8</td>
              <td>38.1</td>
              <td>39.4</td>
            </tr>
          </Table>
        )}
      </Container>
    </>
  );
};
