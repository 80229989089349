import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import axios from "axios";
import { SERVER_PATH } from "../../config/env";

const slideSlides = {
  height: "300px",
  width: "100vw",
};

export default function Sliders() {
  const [image, setImage] = useState([]);

  const fetchData = () => {
    axios.get(`${SERVER_PATH}get/user_landingpage.php`).then((res) => {
      setImage(res.data);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <>
      {image.length > 0 ? (
        <Slider {...settings} className="">
          {Array.isArray(image) &&
            image.length > 0 &&
            image.map((data) => {
              return (
                <div key={data.image_id}>
                  <a href={data.link} target="blank">
                    <img
                      src={data.path.replace(/\\/g, "\\\\")}
                      alt="ktownaaricreatives offers"
                      className="img-fluid slider"
                      style={slideSlides}
                    />
                  </a>
                </div>
              );
            })}
        </Slider>
      ) : null}
    </>
  );
}
