import React, { useEffect } from "react";
import "../assets/css/slick-theme.css";
import Sliders from "./Carousal";
import ProductCard from "./Products/ProductLayout";
import ProductsOfTheMonth from "./Products/ProductsOfTheMonth";

export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Sliders />

      {/* Products category */}
      <section className="bg-light">
        <div className="container py-3">
          <div className="row text-center py-3">
            <div className="col-lg-6 m-auto">
              <h1 className="h2">Our Products</h1>
              <p>We love curating; We love discovering new products</p>
            </div>
          </div>
          <div className="row">
            <ProductCard />
          </div>
        </div>
      </section>

      {/* Categories Menu */}
      <section className="container py-0">
        <div className="row text-center pt-2">
          <div className="col-lg-6 m-auto">
            <h1 className="h2 mt-4" style={{ fontWeight: "400" }}>
              Products Of The Month
            </h1>
            <p>We love curating; We love discovering new products</p>
          </div>
        </div>
        <div className="row">
          <ProductsOfTheMonth />
        </div>
      </section>
    </>
  );
}
