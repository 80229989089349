import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BiRupee } from "react-icons/bi";
import { HiBadgeCheck } from "react-icons/hi";
import { BsBookmarkStarFill, BsStarFill, BsWhatsapp } from "react-icons/bs";
import { BiPhoneCall } from "react-icons/bi";
import axios from "axios";
import Rating from "../Rating";
import { ToastContainer } from "react-toastify";
import { SERVER_PATH } from "../../config/env";
import { userInfo } from "../../utils/appInfo";
import { successToaster } from "../../utils/index";
import { getLocalStorage } from "../../utils/helper";
import Come from "../../assets/img/comming.jpg";

const btnAnchorStyles = {
  fontSize: "1em",
  fontWeight: "500",
  textDecoration: "none",
  color: "#fff",
};

export default function Product(props) {
  const isLogin = getLocalStorage("uid") ? true : false;
  let nav = useNavigate();
  const [count, setCount] = useState(1);
  const [ratings, setRatings] = useState(0);
  const [allRating, setAllRating] = useState();
  const [data, setData] = useState([]);
  const param = useParams();
  const [userData, setUserData] = useState(false);
  // const [userRating, setUserRating] = useState([]);
  const [productData, setProductData] = useState({
    colour: "#00000",
    size: "",
  });

  const handleIncrement = () => {
    setCount((prevCount) => prevCount + 1);
  };

  const handleDecrement = () => {
    if (count != 1) setCount((prevCount) => prevCount - 1);
  };

  const toggleState = (clickState) => {
    setRatings(clickState);
  };

  const dataHandler = (e) => {
    const productArr = { ...productData };
    productArr[e.target.name] = e.target.value;
    setProductData(productArr);
  };

  const fetchData = () => {
    axios
      .post(`${SERVER_PATH}get/product.php`, {
        product_id: param.productId,
      })
      .then((res) => {
        let info = res.data.length ? res.data[0] : {};
        setData(info);
        setCount(1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const postData = async (e) => {
    if (isLogin === false) {
      nav("/profile/:productId");
    } else {
      e.preventDefault();
      let info = {
        product_id: param.productId,
        user_id: userData.email,
        quantity: count,
      };

      axios
        .post(`${SERVER_PATH}post/cart.php`, { ...info })
        .then((res) => {
          successToaster({ msg: "Added to cart" });
          setCount(1);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const buyPostData = async (e) => {
    if (isLogin === false) {
      nav("/profile/:productId");
    } else {
      e.preventDefault();
      let info = {
        product_id: param.productId,
        user_id: userData.email,
        quantity: count,
      };

      axios
        .post(`${SERVER_PATH}post/cart.php`, { ...info })
        .then((res) => {
          nav("/cart/:productId");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const swapFocusImg = (key) => {
    let info = { ...data };
    info[key] = info.img1;
    info.img1 = data[key];
    setData(info);
  };

  const overallRating = async () => {
    await axios
      .post(`${SERVER_PATH}get/ratings.php`, {
        product_id: param.productId,
      })
      .then((res) => {
        setAllRating(res.data);
      });
  };

  // const getUserRating = async () => {
  //   await axios
  //     .post(`${SERVER_PATH}get/ratings.php`, {
  //       user_id: userData.email,
  //       product_id: param.productId,
  //     })
  //     .then((res) => {
  //       setUserRating(res.data.rating);
  //     });
  // };

  const submitRating = async (rate) => {
    axios
      .post(`${SERVER_PATH}post/ratings.php`, {
        user_id: userData.email,
        product_id: param.productId,
        rating: rate,
      })
      .then((res) => {
        successToaster({ msg: "Rating added successfully" });
        overallRating();
      });
  };

  useEffect(() => {
    setTimeout(() => {
      setUserData(userInfo());
      fetchData();
      overallRating();
      window.scrollTo(0, 0);
    }, 200);
  }, [param.productId]);

  return (
    <>
      <section className="bg-light" key={data.product_id}>
        <div className="container pb-5">
          <div className="row">
            <div className="col-lg-4 mt-5">
              <div className="container">
                {/* <div
                  id="multi-item-example"
                  className="carousel slide carousel-multi-item"
                  data-bs-ride="carousel"
                >
                  <div
                    className="carousel-inner product-links-wap"
                    role="listbox"
                  > */}
                {/* <div className="carousel-item active"> */}
                {/* <div className="row ">
                  <div className="col-lg-3 d-flex flex-column" >
                    <div
                      onClick={() => {
                        swapFocusImg("img2");
                      }}
                    >
                      <img
                        className="card-img img-fluid"
                        src={data.img2 ? data.img2 : Come}
                        style={{
                          height: "120px",
                          objectFit: "cover",
                        }}
                        alt=""
                      />
                    </div>
                    <div
                      onClick={() => {
                        swapFocusImg("img3");
                      }}
                    >
                      <img
                        className="card-img img-fluid"
                        src={data.img3 ? data.img3 : Come}
                        style={{
                          height: "120px",
                          objectFit: "cover",
                        }}
                        alt=""
                      />
                    </div>
                    <div
                      onClick={() => {
                        swapFocusImg("img4");
                      }}
                    >
                      <img
                        className="card-img img-fluid"
                        src={data.img4 ? data.img4 : Come}
                        style={{
                          height: "120px",
                          objectFit: "cover",
                        }}
                        alt=""
                      />
                    </div>
                  </div> */}

                {/* </div> */}
                {/* </div> */}
                {/* </div> */}
                {/* <div className="container-fluid"> */}
                {/* <div className="row"> */}
                <div className="row ">
                <div className="col-lg-12 ">
                  <div className="card mb-3 m-0 ">
                    <img
                      className="card-img img-fluid "
                      src={data.img1 ? data.img1 : Come}
                      style={{ height: "460px", objectFit: "cover" }}
                      alt=""
                      id="product-detail"
                    />
                  </div>
                  {/* </div> */}
                </div>
                {/* <div className="row "> */}
                {/* <div> */}
                <div
                  className="col-lg-4"
                  onClick={() => {
                    swapFocusImg("img2");
                  }}
                >
                  <img
                    className="card-img img-fluid"
                    src={data.img2 ? data.img2 : Come}
                    style={{
                      height: "125px",
                      objectFit: "cover",
                    }}
                    alt=""
                  />
                </div>
                <div
                  className="col-lg-4"
                  onClick={() => {
                    swapFocusImg("img3");
                  }}
                >
                  <img
                    className="card-img img-fluid"
                    src={data.img3 ? data.img3 : Come}
                    style={{
                      height: "125px",
                      objectFit: "cover",
                    }}
                    alt=""
                  />
                </div>
                <div
                  className="col-lg-4"
                  onClick={() => {
                    swapFocusImg("img4");
                  }}
                >
                  <img
                    className="card-img img-fluid"
                    src={data.img4 ? data.img4 : Come}
                    style={{
                      height: "125px",
                      objectFit: "cover",
                    }}
                    alt=""
                  />
                  {/* </div> */}
                  </div>
                </div>
                <div className="col-1 align-self-center"></div>
                {/* </div> */}
              </div>
            </div>
            <div className="col-lg-8 mt-5">
              <div className="card">
                <div className="card-body">
                  <h1
                    className="h5 text-uppercase"
                    style={{ color: "#757575", fontSize: "25px" }}
                  >
                    {data.product_name}{" "}
                    <small style={{ fontSize: "15px" }}>
                      ({data.weight} Gms.)
                    </small>
                  </h1>
                  <p>
                    <span
                      className="list-inline-item text-dark fw-700"
                      style={{ fontSize: "1.4em" }}
                    >
                      <BiRupee style={{ marginTop: "-3px" }} />
                      {data.offer_price}
                    </span>
                    <span
                      className="list-inline-item text-secondary"
                      style={{
                        fontSize: "1em",
                        textDecoration: "line-through",
                      }}
                    >
                      <BiRupee style={{ marginTop: "-3px" }} />
                      {data.price}
                    </span>
                    <span
                      className="list-inline-item"
                      style={{ fontSize: "1em", color: "green" }}
                    >
                      {data.offer}%
                    </span>
                  </p>
                  <div className="row">
                    <div className="col-auto">
                      <ul className="list-inline pb-3">
                        <li className="list-inline-item text-right">
                          Quantity
                          <input
                            type="hidden"
                            name="product-quanity"
                            id="product-quanity"
                            value="1"
                          />
                        </li>
                        <li className="list-inline-item">
                          <span
                            className="btn btn-sm btn-success"
                            id="btn-minus"
                            onClick={handleDecrement}
                          >
                            -
                          </span>
                        </li>
                        <li className="list-inline-item">
                          <span
                            className="badge bg-light text-dark"
                            id="var-value"
                          >
                            {count}
                          </span>
                        </li>
                        <li className="list-inline-item">
                          <span
                            className="btn btn-sm btn-success"
                            id="btn-plus"
                            onClick={handleIncrement}
                          >
                            +
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="row pb-3">
                    <div className="col-md-4 col-sm-6 col-12 mb-2 mb-md-0 d-grid">
                      {data.status == 0 ? (
                        <button
                          disabled
                          // onClick={
                          //   isLogin === true
                          //     ? handleShow
                          //     : () => nav("/profile/:productId")
                          // }
                          onClick={buyPostData}
                          type="submit"
                          className="btn btn-success btn-sm py-2 text-uppercase"
                          name="submit"
                          value="buy"
                          style={{
                            borderRadius: "0",
                            letterSpacing: ".2px",
                            fontWeight: "500",
                          }}
                        >
                          Buy Now
                        </button>
                      ) : (
                        <button
                          // onClick={
                          //   isLogin === true
                          //     ? handleShow
                          //     : () => nav("/profile/:productId")
                          // }
                          onClick={buyPostData}
                          type="submit"
                          className="btn btn-success btn-sm py-2 text-uppercase"
                          name="submit"
                          value="buy"
                          style={{
                            borderRadius: "0",
                            letterSpacing: ".2px",
                            fontWeight: "500",
                          }}
                        >
                          Buy Now
                        </button>
                      )}
                    </div>
                    <div className="col-md-4 col-sm-6 col-12 mb-2 mb-md-0 d-grid">
                      {data.status == 0 ? (
                        <button
                          disabled
                          onClick={postData}
                          type="submit"
                          className="btn btn-sm py-2 text-uppercase text-light"
                          name="submit"
                          value="addtocard"
                          style={{
                            borderRadius: "0",
                            backgroundColor: "#FFAB00",
                            letterSpacing: ".2px",
                            fontWeight: "500",
                          }}
                        >
                          Add To Cart
                        </button>
                      ) : (
                        <button
                          onClick={postData}
                          type="submit"
                          className="btn btn-sm py-2 text-uppercase text-light"
                          name="submit"
                          value="addtocard"
                          style={{
                            borderRadius: "0",
                            backgroundColor: "#FFAB00",
                            letterSpacing: ".2px",
                            fontWeight: "500",
                          }}
                        >
                          Add To Cart
                        </button>
                      )}
                    </div>
                    <div className="col-md-4 col-sm-12 col-12 mb-2 mb-md-0 d-grid">
                      {data.status == 0 ? (
                        <button
                          disabled
                          type="submit"
                          className="btn btn-sm text-light d-flex align-items-center justify-content-center"
                          name="submit"
                          value="addtocard"
                          style={{
                            borderRadius: "0",
                            backgroundColor: "#44bd32",
                            letterSpacing: ".2px",
                            fontWeight: "500",
                          }}
                        >
                          <BsWhatsapp
                            style={{ fontSize: "1.3em" }}
                            className="me-1"
                          />
                          <a
                            target={"blank"}
                            href="https://wa.me/message/GNIAAKBJ23RTO1"
                            style={btnAnchorStyles}
                          >
                            Book Now
                          </a>
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-sm text-light d-flex align-items-center justify-content-center"
                          name="submit"
                          value="addtocard"
                          style={{
                            borderRadius: "0",
                            backgroundColor: "#44bd32",
                            letterSpacing: ".2px",
                            fontWeight: "500",
                            fontSize: 15,
                            padding: "8px",
                          }}
                        >
                          <BsWhatsapp
                            style={{ fontSize: "1.3em" }}
                            className="me-1"
                          />
                          <a
                            target={"blank"}
                            href="https://wa.me/message/GNIAAKBJ23RTO1"
                            style={btnAnchorStyles}
                          >
                            Book Now
                          </a>
                        </button>
                      )}
                    </div>
                    {/* <div className="col-md-3 col-6 mb-md-0 d-grid">
                      {data.status == 0 ? (
                        <button
                          disabled
                          type="submit"
                          className="btn btn-sm text-light d-flex align-items-center justify-content-center"
                          name="submit"
                          value="addtocard"
                          style={{
                            borderRadius: "0",
                            backgroundColor: "#3F51B5",
                            letterSpacing: ".2px",
                            fontWeight: "500",
                          }}
                        >
                          <BiPhoneCall
                            style={{ fontSize: "1.3em" }}
                            className="me-1"
                          />
                          <a href="tel:8610922601" style={btnAnchorStyles}>
                            Call Now
                          </a>
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-sm text-light d-flex align-items-center justify-content-center"
                          name="submit"
                          value="addtocard"
                          style={{
                            borderRadius: "0",
                            backgroundColor: "#3F51B5",
                            letterSpacing: ".2px",
                          }}
                        >
                          <BiPhoneCall
                            style={{ fontSize: "1.3em" }}
                            className="me-1"
                          />
                          <a href="tel:8610922601" style={btnAnchorStyles}>
                            Call Now
                          </a>
                        </button>
                      )}
                    </div> */}
                  </div>
                  <h6
                    className="text-uppercase mt-2"
                    style={{ color: "#757575" }}
                  >
                    Description:
                  </h6>
                  <p
                    style={{
                      fontSize: "15px",
                      textAlign: "justify",
                      lineHeight: "1.7em",
                      fontWeight: "350",
                    }}
                  >
                    {data.description}
                  </p>
                  <h6
                    className="text-uppercase mt-3"
                    style={{ color: "#000", fontSize: "15px" }}
                  >
                    <HiBadgeCheck
                      style={{
                        marginTop: "-4px",
                        color: "green",
                        fontSize: "20px",
                      }}
                      className="me-1"
                    />
                    Material:
                  </h6>
                  <ul className="text-capitalize pb-2 ingrediants">
                    <li>{data.ingredients}</li>
                  </ul>
                  <h6
                    className="text-uppercase mt-3"
                    style={{ color: "#000", fontSize: "15px" }}
                  >
                    <HiBadgeCheck
                      style={{
                        marginTop: "-4px",
                        color: "green",
                        fontSize: "20px",
                      }}
                      className="me-1"
                    />
                    instructions:
                  </h6>
                  <p
                    style={{
                      fontSize: "15px",
                      textAlign: "justify",
                      lineHeight: "1.7em",
                      fontWeight: "350",
                    }}
                  >
                    {data.instructions}
                  </p>
                </div>
              </div>
              <div className="card mt-2">
                <div className="card-body">
                  <div className="d-flex align-items-center position-relative ratingDiv">
                    <h1
                      className="h5 text-capitalize"
                      style={{ color: "#000", fontSize: "20px" }}
                    >
                      Ratings
                    </h1>
                    <button
                      className="position-absolute end-0"
                      onClick={(e) => {
                        toggleState(1);
                      }}
                    >
                      Rate Product
                    </button>
                  </div>
                  <h6
                    className="text-uppercase mt-3 mb-3"
                    style={{ color: "#000", fontSize: "15px" }}
                  >
                    <BsBookmarkStarFill
                      style={{
                        marginTop: "-4px",
                        color: "#f39c12",
                        fontSize: "20px",
                      }}
                      className="me-1"
                    />
                    Overall Ratings
                  </h6>
                  <div className="d-flex align-items-center">
                    <span style={{ fontSize: "1.5em" }}>{allRating}</span>
                    &nbsp;
                    <BsStarFill />
                    &nbsp;
                  </div>
                </div>
                {ratings === 1 ? (
                  <div className="card-body">
                    <h5>Enter Your Ratings</h5>
                    <Rating
                      rating={ratings}
                      onRating={(rate) => {
                        setRatings(rate);
                      }}
                      submitRating={submitRating}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
}
