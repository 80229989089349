import { BrowserRouter, Routes, Route } from "react-router-dom";
import AppHeader from "../components/Header";
import Home from "../components";
import Cart from "../components/Cart";
import MobNavbar from "../components/NavBar/Mobile";
import Profile from "../components/Profile";
import Login from "../components/Login";
import ErrorPage from "../components/NotFound";
import UserContextProvider from "../context/userContext";
import { PrivateRouter } from "../components/Router/PrivateRouter";
import Product from "../components/Products";
import About from "../components/About";
import Footer from "../components/Footer";
import Beads from "../components/Products/Beads";
import Thread from "../components/Products/Thread";
import Zardosi from "../components/Products/Zardosi";
import { Sizechart } from "../components/Sizechart";

function App() {
  return (
    <>
      <UserContextProvider>
        <BrowserRouter path="/" basename={process.env.PUBLIC_URL}>
          <AppHeader />
          <MobNavbar />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route
              path="/cart/:productId"
              element={
                <PrivateRouter>
                  <Cart />
                </PrivateRouter>
              }
            />
            <Route
              path="/profile/:productId"
              element={
                <PrivateRouter>
                  <Profile />
                </PrivateRouter>
              }
            />
            <Route path="/beads" element={<Beads />} />
            <Route path="/thread" element={<Thread />} />
            <Route path="/zardosi" element={<Zardosi />} />
            <Route path="/sizechart" element={<Sizechart />} />
            {/* others */}
            <Route path="/product/:productId" element={<Product />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </UserContextProvider>
    </>
  );
}

export default App;
