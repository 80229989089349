import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { BsCoin } from "react-icons/bs";
import { ImTarget } from "react-icons/im";
import { FaChartLine } from "react-icons/fa";
import aboutHero from "../../assets/img/about-hero.png";
import inspiration from "../../assets/img/inspiration.jpg";
import infrastructure from "../../assets/img/infrastructure.jpg";
import incrediants from "../../assets/img/incrediants-standard.jpg";
import cultivation from "../../assets/img/cultivation.jpg";
import AboutCard from "./AboutCard";

export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fullViewInspiration = () => {
    Swal.fire({
      title: "INSPIRATION",
      text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.Laudantium dolorem vero eius magnam quas dolores beatae, illum temporibus distinctio, quia quod eos provident accusamus repellat harum ea tempore corrupti alias.",
    });
  };

  const fullViewInfrastructure = () => {
    Swal.fire({
      title: "INFRASTRUCTURE",
      text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.Laudantium dolorem vero eius magnam quas dolores beatae, illum temporibus distinctio, quia quod eos provident accusamus repellat harum ea tempore corrupti alias.",
    });
  };

  const fullViewIngrediants = () => {
    Swal.fire({
      title: "DELIVERY SERVICE",
      text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.Laudantium dolorem vero eius magnam quas dolores beatae, illum temporibus distinctio, quia quod eos provident accusamus repellat harum ea tempore corrupti alias.",
    });
  };

  const fullViewCultivation = () => {
    Swal.fire({
      title: "SHIPPING & RETURN",
      text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.Laudantium dolorem vero eius magnam quas dolores beatae, illum temporibus distinctio, quia quod eos provident accusamus repellat harum ea tempore corrupti alias.",
    });
  };

  return (
    <>
      <section class="py-5 about-hero">
        <div class="container">
          <div class="row align-items-center py-5">
            <div class="col-md-8 text-white">
              <h1>About Us</h1>
              <p>
                JAYPEE Sports is your one stop supplier for custom sports,
                corporate and school wear. We source fabrics from the best
                manufacturers to bring you, quality sportswear at best prices.
                We offer a range of sportswear specific to each sport like
                football, cricket, kabaddi, athletics, volleyball, basketball,
                general sporting uniforms, and also casual wear products. Our
                products come in a range of colors with the option of additional
                embroidery or screen-printing.
              </p>
            </div>
            <div class="col-md-4">
              <img src={aboutHero} alt="About Hero" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>
      <section class="container py-3">
        <div class="row text-center pt-5 pb-3">
          <div class="col-lg-12 m-auto">
            <h1 class="h1">Our Story</h1>
            <p>
              Our qualified graphic designers can customize our fully sublimated
              products with any color and pattern to suit your team. Our
              friendly team will work hand in hand with you and your team to
              create a kit that will stick in the memory of your competitors.
              JAYPEE Sports guarantees that any order you make with us will be
              delivered to you within two weeks of it being placed with a 50%
              deposit.
            </p>
          </div>
        </div>
      </section>
      <section class="container py-1">
        <Row>
          <Col lg={3}>
            <AboutCard
              image={inspiration}
              cardStyle="aboutCard"
              title="Inspiration"
              contents="Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Laudantium dolorem vero eius magnam quas dolores beatae,"
              readMore={() => fullViewInspiration()}
            />
          </Col>
          <Col lg={3}>
            <AboutCard
              image={infrastructure}
              cardStyle="aboutCard"
              title="Infrastructure"
              contents="Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Laudantium dolorem vero eius magnam quas dolores beatae,"
              readMore={() => fullViewInfrastructure()}
            />
          </Col>
          <Col lg={3}>
            <AboutCard
              image={incrediants}
              cardStyle="aboutCard"
              title="Delivey Services"
              contents="Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Laudantium dolorem vero eius magnam quas dolores beatae,"
              readMore={() => fullViewIngrediants()}
            />
          </Col>
          <Col lg={3}>
            <AboutCard
              image={cultivation}
              cardStyle="aboutCard"
              title="Shipping & Return"
              contents="Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Laudantium dolorem vero eius magnam quas dolores beatae,"
              readMore={() => fullViewCultivation()}
            />
          </Col>
        </Row>
        <div class="row d-flex justify-content-center my-3">
          <div class="col-md-4 col-lg-4">
            <div class="px-5 services-icon-wap">
              <div class="h1 aboutIcon text-center">
                <ImTarget />
              </div>
              <h2 class="h5 mt-4 text-center">Mission</h2>
              <p
                style={{
                  textAlign: "justify",
                  fontSize: "15px",
                  fontWeight: "400",
                }}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Laudantium dolorem vero eius magnam quas dolores beatae, illum
                temporibus distinctio, quia quod eos provident accusamus
                repellat harum ea tempore corrupti alias.
              </p>
            </div>
          </div>

          <div class="col-md-4 col-lg-4">
            <div class="px-5 services-icon-wap">
              <div class="h1 aboutIcon text-center">
                <FaChartLine />
              </div>
              <h2 class="h5 mt-4 text-center">Vision</h2>
              <p
                style={{
                  textAlign: "justify",
                  fontSize: "15px",
                  fontWeight: "400",
                }}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Laudantium dolorem vero eius magnam quas dolores beatae, illum
                temporibus distinctio, quia quod eos provident accusamus
                repellat harum ea tempore corrupti alias.
              </p>
            </div>
          </div>

          <div class="col-md-4 col-lg-4">
            <div class="px-5 services-icon-wap">
              <div class="h1 aboutIcon text-center">
                <BsCoin />
              </div>
              <h2 class="h5 mt-4 text-center">Values</h2>
              <p
                style={{
                  textAlign: "justify",
                  fontSize: "15px",
                  fontWeight: "400",
                }}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Laudantium dolorem vero eius magnam quas dolores beatae, illum
                temporibus distinctio, quia quod eos provident accusamus
                repellat harum ea tempore corrupti alias.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
